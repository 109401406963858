export function newsPage() {
  document
    .querySelectorAll(".component-news-page__filter")
    .forEach((wrapper) => {
      wrapper.querySelectorAll("select").forEach((select) => {
        select.addEventListener("change", function (e) {
          const selectedOption: HTMLOptionElement =
            // @ts-ignore
            e.target.options[e.target.selectedIndex];
          if (selectedOption.dataset.url) {
            window.location.href = selectedOption.dataset.url;
          }
        });
      });
    });
}
