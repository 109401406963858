export function anchorLineBelow() {
  // Turn all lonley links into special styled links!
  const anchors = document.querySelectorAll(
    ".component-free-text p > a, .component-hero-in-page__content p > a"
  ) as NodeListOf<HTMLAnchorElement>;
  anchors.forEach((anchor) => {
    if (!anchor.nextSibling && !anchor.previousSibling) {
      anchor.classList.add("anchor-line-below");
    }
  });
}
