type OpenState = "open" | "closed";

function mobileMenu(
  items: NodeListOf<HTMLLIElement>,
  outerElement: Element,
  options?: {
    onToggle?: (open: OpenState) => void;
    onError?: (error: unknown) => void;
  }
) {
  try {
    // All internal state is here!
    let openState: OpenState = "closed";

    // This wrapper will have a button for toggling the visisbility
    // of the menu, and a <ul> for all the items.
    const menuWrapper = document.createElement("div");
    menuWrapper.className = "mobile-menu";

    // The UL hodling the children...
    const menuUl = document.createElement("ul");
    menuUl.className = "mobile-menu__menu";

    // Add clones of all the current menu items...
    for (let i = 0; i < items.length; i++) {
      menuUl.appendChild(items[i].cloneNode(true));
    }

    // The button
    const menuButton = document.createElement("button");
    menuButton.textContent = "☰";
    menuButton.className = "mobile-menu__button";

    // Add fold out sub menu buttons to the list items, only top level
    menuUl.querySelectorAll("li:not(li li)").forEach((li) => {
      // Only add the fold out button to list items with sub menus
      if (li.querySelector(".sub-menu")) {
        const foldOutButton = document.createElement("button");
        foldOutButton.textContent = "×";
        foldOutButton.classList.add("mobile-menu__fold-out-button");

        foldOutButton.addEventListener("click", () => {
          foldOutButton.classList.toggle("mobile-menu__fold-out-button--open");
          li.classList.toggle("menu-item--open");
          // This is for a little fade in animation...
          li.classList.add("menu-item--opened-from-ui");
        });

        // If the current item is a child of this, fold it out!
        if (li.querySelector(".sub-menu .current-menu-item")) {
          foldOutButton.classList.add("mobile-menu__fold-out-button--open");
          li.classList.add("menu-item--open");
        }

        // Add the button to the LI
        li.appendChild(foldOutButton);
      }
    });

    // We also need to add the close menu function to the inner close button
    function closeOnClick(e: MouseEvent) {
      if (e.target && menuUl.contains(e.target as Node)) {
        // Do not close the thing...
      } else {
        closeMenu();
      }
    }

    // Closing the menu...
    function closeMenu() {
      menuButton.textContent = "☰";
      menuUl.classList.remove("mobile-menu__menu--open");
      menuButton.classList.remove("mobile-menu__button--open");
      document.removeEventListener("click", closeOnClick);
      openState = "closed";
      options?.onToggle && options?.onToggle(openState);
    }

    // Function for toggling the menu
    function toggleMenu() {
      openState = openState === "open" ? "closed" : "open";
      if (openState === "open") {
        // Also allow clicks outside the menu to close the menu!
        setTimeout(() => document.addEventListener("click", closeOnClick));

        menuButton.textContent = "✕";
        menuUl.classList.add("mobile-menu__menu--open");
        menuButton.classList.add("mobile-menu__button--open");
        options?.onToggle && options?.onToggle(openState);
      } else {
        closeMenu();
      }
    }
    menuButton.addEventListener("click", toggleMenu);

    // Add the items...
    menuWrapper.appendChild(menuButton);
    menuWrapper.appendChild(menuUl);

    // Add the mobile menu to the DOM
    outerElement.parentNode?.insertBefore(menuWrapper, outerElement);

    // Let's return some helpers to change the state from outside
    // if that would be needed.
    return { toggleMenu };
  } catch (e: unknown) {
    console.error("Error when running mobileMenu()", e);
    options?.onError && options?.onError(e);
  }
}

export function bergendahlsMobileMenu() {
  try {
    // We need a bunch of items and an outer element to put
    // the mobile menu inside.
    const items: NodeListOf<HTMLLIElement> = document.querySelectorAll(
      ".header__nav-menu-secondary .menu > li"
    );
    const outerElement = document.querySelector(
      ".header__nav-menu-secondary .menu"
    );

    // (We currently do not care about the returned toggleMenu function.)
    outerElement &&
      mobileMenu(items, outerElement, {
        onToggle: (open) => {
          document.body.classList.toggle(
            "mobile-menu-is-open",
            open === "open"
          );
          // remove all toggle-from-ui items on close!
          if (open === "closed") {
            document
              .querySelectorAll(".menu-item--opened-from-ui")
              .forEach((e) => e.classList.remove("menu-item--opened-from-ui"));
          }
        },
      });
  } catch (e) {
    console.error("Error when running bergendahlsMobileMenu()", e);
  }
}
