import "../css/main";
import { bergendahlsMobileMenu } from "../header-default";
import { newsPage } from "../templates/components/news_page";
import { statsAndImage } from "../templates/components/stats_and_image";
import { anchorLineBelow } from "./links";
import { focusOnSearch } from "./search";

const components: Array<() => void> = [
  bergendahlsMobileMenu,
  anchorLineBelow,
  focusOnSearch,
  newsPage,
  statsAndImage,
];

document.addEventListener("DOMContentLoaded", () => {
  components.forEach((c) => c());
});
