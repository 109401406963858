export function statsAndImage() {
  document
    .querySelectorAll(
      ".component-stats-and-image--with-link .component-stats-and-image__image-wrapper"
    )
    .forEach((e) => {
      const anchor = e.querySelector("a");
      const url = anchor?.href;
      url &&
        e.addEventListener("click", () => {
          window.location.href = url;
        });
    });
}
